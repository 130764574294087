import React, { useState, useCallback, useEffect, useRef } from 'react';
import { captureException, captureMessage } from '@sentry/gatsby';
import {
  Flex,
  Box,
  Button,
  Heading,
  Text,
  Input,
  Label,
  Radio,
} from 'theme-ui';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { PulseLoader } from 'react-spinners';
import fetch from 'isomorphic-unfetch';
import { usePlacesWidget } from 'react-google-autocomplete';
import { Trans, t } from '@lingui/macro';

const shopName = process.env.GATSBY_SHOPIFY_SHOP_NAME;

const OrderForm = ({
  hrefLang,
  buttonEnabled,
  lineItems,
  subtotalPrice,
  checkoutId,
}) => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [street, setStreet] = useState('');
  const [building, setBuilding] = useState('');
  const [appartment, setAppartment] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [orderItems, setOrderItems] = useState(false);
  const [epHash, setEpHash] = useState('');
  const [epOrderNo, setEpOrderNo] = useState('');
  const [epSum, setEpSum] = useState(0);

  const [showSpinner, setShowSpinner] = useState(false);
  const [submitForm, setSubmitForm] = useState(false);

  const epMerNo = process.env.GATSBY_EP_MerNo;
  const epExpires = process.env.GATSBY_EP_Expires;
  const epSuccessUrl = process.env.GATSBY_EP_Success_URL;
  const epCancelUrl = process.env.GATSBY_EP_Cancel_URL;
  const epDebug = process.env.GATSBY_EP_Debug;

  const googlePlacesApiKey = process.env.GATSBY_GOOGLE_PLACES_API_KEY;

  const formRef = useRef(null);

  const { ref } = usePlacesWidget({
    apiKey: googlePlacesApiKey,
    options: {
      types: ['address'],
    },
    language: hrefLang === 'ru-BY' ? 'ru' : undefined,
    onPlaceSelected: (place, ref) => {
      if (
        place &&
        place.address_components &&
        place.address_components.length > 0
      ) {
        place.address_components.forEach((c) => {
          if (c.types.includes('street_number')) {
            setBuilding(c.long_name);
          }
          if (c.types.includes('locality')) {
            setCity(c.long_name);
          }
          if (c.types.includes('administrative_area_level_1')) {
            setState(c.long_name);
          }
          if (c.types.includes('country')) {
            setCountry(c.long_name);
          }
          if (c.types.includes('postal_code')) {
            setPostalCode(c.long_name);
          }
          if (c.types.includes('route')) {
            setStreet(c.long_name);
            ref.value = c.long_name;
          }
        });
      }
    },
  });

  const handleEmailChange = useCallback((e) => {
    setEmail(e.value);
  });
  const handlePhoneChange = useCallback((value) => {
    setPhone(value);
  });
  const handleFirstNameChange = useCallback((e) => {
    setFirstName(e.value);
  });
  const handleLastNameChange = useCallback((e) => {
    setLastName(e.value);
  });
  const handleAppartmentChange = useCallback((e) => {
    setAppartment(e.value);
  });
  const handleBuildingChange = useCallback((e) => {
    setBuilding(e.value);
  });
  const handleCountryChange = useCallback((e) => {
    setCountry(e.value);
  });
  const handleStateChange = useCallback((e) => {
    setState(e.value);
  });
  const handlePostalCodeChange = useCallback((e) => {
    setPostalCode(e.value);
  });
  const handleCityChange = useCallback((e) => {
    setCity(e.value);
  });

  const notifyError = () => {
    captureMessage(
      'Error while trying to place an order: ' +
        JSON.stringify({
          phone,
          email,
          firstName,
          lastName,
          country,
          state,
          city,
          street,
          building,
          appartment,
          postalCode,
          orderItems,
          subtotalPrice,
        })
    );
    window.alert(
      t({
        id: 'OrderForm.ErrorAlert',
        message:
          'Something went wrong and we could not place your order. Please try again. If this error appears again please contact our manager and we will help you to place your order.',
      })
    );
    setSubmitForm(false);
    setShowSpinner(false);
  };

  useEffect(() => {
    let tempOrederItems = [];
    if (lineItems && lineItems.length > 0) {
      lineItems.forEach(({ id, title, quantity, variant }, index) => {
        const {
          id: variantId,
          available,
          title: variantTitle,
          sku,
          weight,
          priceV2: { amount, currencyCode },
        } = variant;

        tempOrederItems.push({
          id,
          title,
          quantity,
          variant: {
            id: variantId,
            available,
            title: variantTitle,
            sku,
            weight,
            priceV2: { amount, currencyCode },
          },
        });
      });

      setOrderItems(tempOrederItems);
    }
  }, [lineItems]);

  useEffect(() => {
    if (submitForm) {
      formRef.current.submit();
    }
  }, [submitForm]);

  return (
    <Box
      as="form"
      ref={formRef}
      action="https://ssl.easypay.by/weborder/"
      onSubmit={async (e) => {
        e.preventDefault();

        setShowSpinner(true);
        if (submitForm) {
          e.target.submit();
        } else {
          const data = new FormData(e.target);
          let response;
          try {
            response = await fetch(
              'https://kisscurls-order.gatsbystorefront.workers.dev/order',
              {
                method: 'POST',
                body: data,
              }
            ).then((r) => r.json());
          } catch (e) {
            notifyError();
            console.error(e);
            captureException(e);
          }

          const {
            status,
            EP_Hash,
            EP_OrderNo,
            amount,
            currencyCode,
          } = response;

          if (
            status === 'ok' &&
            EP_Hash &&
            EP_OrderNo &&
            amount &&
            currencyCode
          ) {
            setEpHash(EP_Hash);
            setEpOrderNo(EP_OrderNo);
            setEpSum(amount);
            setSubmitForm(true);
          } else {
            notifyError();
          }
        }
      }}
      method="POST"
      sx={{ px: 2, pt: 3, maxWidth: 900, mx: 'auto' }}
    >
      <Box my={3}>
        <Heading as="h3">
          <Trans id="OrderForm.ContactHeading">Contact Information</Trans>
        </Heading>
        <Input
          name="email"
          type="email"
          value={email}
          onChange={handleEmailChange}
          placeholder={t({ id: 'OrderForm.EmailLabel', message: 'E-mail' })}
          required
          my={2}
        />
        <PhoneInput
          inputComponent={Input}
          international
          defaultCountry={hrefLang === 'ru-BY' ? 'BY' : false}
          name="phone"
          value={phone}
          onChange={handlePhoneChange}
          placeholder={t({
            id: 'OrderForm.PhoneLabel',
            message: 'Phone number',
          })}
          required
          my={2}
        />
      </Box>
      <Box my={3}>
        <Heading as="h3">
          <Trans id="OrderForm.ShippingHeading">Shipping address</Trans>
        </Heading>
        <Flex>
          <Input
            name="firstName"
            value={firstName}
            onChange={handleFirstNameChange}
            placeholder={t({
              id: 'OrderForm.FirstNameLabel',
              message: 'First Name',
            })}
            required
            my={2}
            mr={2}
          />
          <Input
            name="lastName"
            value={lastName}
            onChange={handleLastNameChange}
            placeholder={t({
              id: 'OrderForm.LastNameLabel',
              message: 'Last Name',
            })}
            required
            my={2}
          />
        </Flex>
        <Input
          name="address"
          ref={ref}
          placeholder={t({
            id: 'OrderForm.AddressLabel',
            message: 'Address',
          })}
          required
          my={2}
        />
        <Input
          name="building"
          value={building}
          onChange={handleBuildingChange}
          placeholder={t({
            id: 'OrderForm.BuildingLabel',
            message: 'House, building etc.',
          })}
          required
          my={2}
        />
        <Input
          name="appartment"
          value={appartment}
          onChange={handleAppartmentChange}
          placeholder={t({
            id: 'OrderForm.AppartmentLabel',
            message: 'Apartment, suite, etc. (optional)',
          })}
          my={2}
        />
        <Input
          name="city"
          value={city}
          onChange={handleCityChange}
          placeholder={t({
            id: 'OrderForm.CityLabel',
            message: 'City',
          })}
          required
          my={2}
        />
        <Flex my={2}>
          <Input
            name="country"
            value={country}
            onChange={handleCountryChange}
            placeholder={t({
              id: 'OrderForm.CountryLabel',
              message: 'Country',
            })}
            required
            mr={2}
          />
          <Input
            name="state"
            value={state}
            onChange={handleStateChange}
            placeholder={t({
              id: 'OrderForm.StateLabel',
              message: 'State',
            })}
            required
            mr={2}
          />
          <Input
            name="postalCode"
            value={postalCode}
            onChange={handlePostalCodeChange}
            placeholder={t({
              id: 'OrderForm.PostalCodeLabel',
              message: 'ZIP/Postal code',
            })}
            required
          />
        </Flex>
      </Box>
      <Box my={3}>
        <Heading as="h3">
          <Trans id="OrderForm.ShippingMethodHeading">Shipping Method</Trans>
        </Heading>
        <Flex my={2}>
          <Label>
            <Radio name="sippingMethodEms" checked={true} readOnly />
            <Trans id="OrderForm.ShippingMethodEmsLabel">
              EMS international postal mail service
            </Trans>
          </Label>
          <Text>
            <Trans id="OrderForm.ShippingMethodEmsPrice">Free</Trans>
          </Text>
        </Flex>
      </Box>
      <Input
        type="hidden"
        name="oredrItems"
        value={JSON.stringify(orderItems)}
      />
      <Input type="hidden" name="shopName" value={shopName} />
      <Input type="hidden" name="checkoutId" value={checkoutId} />
      <Input type="hidden" name="subtotalPrice" value={subtotalPrice} />
      <Input type="hidden" name="EP_Hash" value={epHash} />
      <Input type="hidden" name="EP_OrderNo" value={epOrderNo} />
      <Input type="hidden" name="EP_MerNo" value={epMerNo} />
      <Input type="hidden" name="EP_Expires" value={epExpires} />
      <Input type="hidden" name="EP_Sum" value={epSum} />
      <Input type="hidden" name="EP_Success_URL" value={epSuccessUrl} />
      <Input type="hidden" name="EP_Cancel_URL" value={epCancelUrl} />
      <Input type="hidden" name="EP_Debug" value={epDebug} />

      <Box my={2}>
        <Button
          disabled={buttonEnabled && orderItems && !showSpinner ? false : true}
          variant="primary"
          px={[3, 5]}
          py={[2, 3]}
          style={{
            opacity: buttonEnabled ? 1 : 0.7,
            width: '100%',
          }}
        >
          {' '}
          {showSpinner ? (
            <PulseLoader
              sx={{ color: 'primary' }}
              loading={true}
              size={6}
              margin={5}
            />
          ) : (
            <Trans id="OrderForm.CheckoutButtonLabel">
              Continue to payment
            </Trans>
          )}
        </Button>

        {/* <CheckoutButton
            as={'a'}
            href={buttonEnabled && webUrl}
            target="_blank"
            rel="nofollow noopener noreferrer"
            variant="primary"
            px={[3, 5]}
            py={[2, 3]}
            style={{
              opacity: buttonEnabled ? 1 : 0.7,
              width: '100%',
            }}
            theme={theme}
          >
            <Trans id="Cart.CheckoutButtonLabel">Continue to payment</Trans>
          </CheckoutButton> */}
      </Box>
    </Box>
  );
};

export default OrderForm;
